var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('create_users'),expression:"'create_users'"}],attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.dialogModal(true, 'create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$('create'))+" ")],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.usersTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, -1],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"item.roles.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.role.color,"label":"","outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(item.role.description)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"dark"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('menu-item',{attrs:{"label":_vm.$('update'),"color":"info","icon":"mdi-pencil"},on:{"click":function($event){return _vm.dialogModal(true, 'update', item.id)}}}),_c('menu-item',{attrs:{"label":_vm.$('delete'),"color":"error","icon":"mdi-delete"},on:{"click":function($event){return _vm.dialogModal(true, 'delete', item.id)}}})],1)],1)],1)]}}],null,true)}),_c('user-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:data":function($event){_vm.dialogData=$event},"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }